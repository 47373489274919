<!-- 后厨管理-制作列表 -->

<template>
	<div class="create-list">
		<div class="felx create-list-item justify-between">
			<div class="felx">
				<div>{{rowDatas.gm_number_sn}}</div>
				<div class="m-m-l grey-text" v-if="screen_id == '1'">{{rowDatas.number}}杯</div>
			</div>
			<div v-if="authData.indexOf('s_HBB5doSdYUOcQ7DQmNZI9fQU4K') != -1" style="display: flex;">
				<el-button v-if="printBtn" @click="onClickPrintBtns" plain round :loading="isLoading"
					class="kitchen-printing">打印</el-button>

				<el-button v-if="printBtn" @click="onClickBtn" type="primary" plain round :loading="isLoading"
					class="make-button">
					{{ btnText ? btnText : "完成" }}</el-button>

				<el-button v-else @click="onClickBtn" type="primary" plain round :loading="isLoading"
					class="make-button">
					{{ btnText ? btnText : "完成" }}</el-button>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, watch, computed, } from 'vue';
	import { useStore } from "vuex";

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	watch(
		() => menuTokens.value,
		(data) => {
			if (data.length) {
				authData.value = data;
			}
		},
		{
			deep: true,
			immediate: true,
		}
	);
	const emit = defineEmits(['onClickBtn', 'onClickPrintBtns'])
	const props = defineProps({
		rowDatas: {  // 当前行数据
			type: Object,
			default: () => { }
		},
		btnText: {  // 按钮名字
			type: String,
			default: ''
		},
		printBtn: {  // 是否显示打印按钮
			type: Boolean,
			default: false
		},
		screen_id: {  // 制作点id
			type: Number,
			default: ''
		}
	})
	const isLoading = ref(false);  // 按钮加载状态
	/**
	 * 
	 * 点击按钮
	 * 
	 * **/
	const onClickBtn = () => {
		isLoading.value = true;
		emit('onClickBtn', props.rowDatas);
		setTimeout(() => {
			isLoading.value = false;
		}, 1000)
	}
	/**
	 * 
	 * 点击打印按钮
	 * 
	 * **/
	const onClickPrintBtns = () => {
		isLoading.value = true;
		emit('onClickPrintBtns', props.rowDatas);
		setTimeout(() => {
			isLoading.value = false;
		}, 1000)
	}
</script>

<style lang="scss" scoped>
	.create-list {
		font-family: "Source Han Sans CN";

		.felx {
			display: flex;
		}

		.justify-between {
			justify-content: space-between;
		}

		.s-m-l {
			margin-left: 10px;
		}

		.m-m-l {
			margin-left: 15px;
		}

		.s-m-b {
			margin-bottom: 10px;
		}

		.grey-text {
			color: var(--text-second-color);
		}

		.create-list-item {
			padding: 10px 16px;
			margin: 0 10px 10px 10px;
			border: 1px solid var(--search-uncheck-bg-color);
			border-radius: 8px;
			line-height: 42px;
			background-color: var(--text-white-color);
			font-size: 18px;
			/* box-shadow: 2px 2px 1px 1px rgba(0,0,0,0.1); */
		}

		.kitchen-printing {
			width: 80px;
			border: 1px solid #F2B800;
			color: #F2B800;
		}

		.make-button {
			width: 80px;
			border: 1px solid var(--btn-bg-color);
			color: var(--btn-bg-color);
		}
	}
</style>