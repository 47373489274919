<!-- 后厨管理 -->

<template>
	<div class="main-cnts" id="middle" :class="isShowMake ? 'middle' : ''">
		<div v-if="isShowMake" class="make-point">
			<div style="color: var(--top-text-color);">请选择制作点</div>
			<div class="flex flex-wrap">
				<div v-for="(item, i) in makeOptions" :key="i" class="cursor-pointer make-point-name"
					@click="onClickName(item)">{{ item.name }}
				</div>
			</div>
		</div>

		<div class="kitchen-manage" v-else>
			<div class="kitchen-content">
				<el-row :gutter="20" style="height: 100%;">
					<el-col :span="8">
						<div class="make to-be-produced">
							<!-- 标题 -->
							<div class="make-title text-white">
								<span>待制作</span>
								<span class="m-m-l">COMING</span>
							</div>

							<!-- 进度 -->
							<div class="subtitle text-white">
								<div class="s-m-b">
									<span
										v-if="screen_id == '1'">{{ statistic_list.current_number }}杯/{{ statistic_list.current_total }}单</span>
								</div>
							</div>

							<!-- 列表 -->
							<el-scrollbar style="height:calc(100vh - 260px);background-color: #0072F6 !important;">
								<div class="s-p-b to-be-produced">
									<div v-if="producedLists.length == 0" class="no-data text-white">暂无数据</div>
									<CreateList v-else v-for="(item, index) in producedLists" :key="index"
										:rowDatas="item" @onClickBtn="onMakeBtn" :btnText="'制作'" :screen_id="screen_id">
									</CreateList>
								</div>
							</el-scrollbar>
						</div>
					</el-col>

					<el-col :span="8">
						<div class="make in-production">
							<!-- 标题 -->
							<div class="make-title text-white">
								<span>制作中</span>
								<span class="m-m-l">ING</span>
							</div>

							<!-- 提示 -->
							<div class="subtitle text-white">
								<div class="s-m-b">当制作完成后工作人员点击完成，提示员工送餐</div>
							</div>

							<!-- 列表 -->
							<el-scrollbar style="height:calc(100vh - 260px);background-color: #F6C101 !important;">
								<div class="s-p-b">
									<div v-if="productionLists.length == 0" class="no-data text-white">暂无数据</div>
									<CreateList v-else v-for="(item, index) in productionLists" :key="index"
										:rowDatas="item" @onClickBtn="onProductioncompletedBtn" :printBtn="true"
										@onClickPrintBtns="onClickPrintBtns" :screen_id="screen_id"></CreateList>
								</div>
							</el-scrollbar>
						</div>
					</el-col>

					<el-col :span="8">
						<div class="make production-completed">
							<!-- 标题 -->
							<div class="make-title">
								<span>制作完成</span>
								<span class="m-m-l">COMPLETE</span>
							</div>

							<!-- 提示 -->
							<div class="subtitle text-black">
								<div class="s-m-b">以下商品已制作完成，工作人员可以为顾客送餐</div>
							</div>

							<!-- 列表 -->
							<el-scrollbar style="height:calc(100vh - 260px);background-color: #B2D3FC !important;">
								<div class="s-p-b">
									<div v-if="completeLists.length == 0" class="no-data text-black">暂无数据</div>
									<div v-else class="flex complete-lists">
										<div v-for="(item, index) in completeLists" :key="index"
											class="complete-list-item">
											{{item.gm_number_sn}}
										</div>
									</div>
								</div>
							</el-scrollbar>
						</div>
					</el-col>
				</el-row>
			</div>

			<!-- 打印标签 -->
			<div style="padding: 5px;" id="printHtmlId" v-show="isShowPrint">
				<div v-for="(item, index) in printDatas.goods_list" :key="index" style="width: 160px;height: 120px;"
					:id="'ticket' + index">
					<div>
						<span style="font-size: 22px;font-weight: bold;margin-right: 10px;">{{ item.number_sn }}</span>
						<span style="font-size: 12px;">{{ item.desc }}</span>
					</div>
					<div style="margin:5px 0">{{ item.goods_name }}</div>
					<div style="margin:5px 0" v-if="item.member_mobile">{{ item.member_mobile }}</div>
					<div style="font-size: 12px;">{{ item.print_date }}</div>
				</div>
			</div>

			<div id="foodPreparation" v-show="isShowfoodPrint">
				<div style="text-align:center;font-size: 18px;padding-top: 10px;">{{printDatas.number_sn}}</div>
				<div style="font-size: 10px;">下单门店：{{printDatas.merchant_name}}</div>
				<div style="border-bottom:2px dashed #eee;font-size: 10px;padding: 5px 0;">
					打印时间：{{printDatas.print_date}}</div>
				<table>
					<tr>
						<td style="font-size: 13px;width: 100px;">商品</td>
						<td style="font-size: 13px;">数量</td>
					</tr>
					<tr v-for="(ticket,t ) in printDatas.goods_list" :key="t" style="padding: 5px 0;">
						<td style="font-size: 14px;width: 100px;">{{ticket.goods_name}}</td>
						<td style="font-size: 16px;">{{ticket.goods_count}}</td>
					</tr>
				</table>
				<div style="border-top:2px dashed #eee;justify-content: space-between;padding: 5px 0;font-size: 13px;">
					<div>
						<span>点菜总数：</span>
						<span style="margin-left: 60px;">{{printDatas.total_num}}</span>
					</div>
				</div>
				<div style="text-align: center;font-size: 12px;">谢谢惠顾</div>
				<div style="height: 2px;background-color: rgba(255, 255, 255, 0.1);font-size: 1px;padding-top:50px;">
					&nbsp;-
				</div>
			</div>
		</div>

		<div v-if="!isShowMake" @click="onReturnBtn" class="return-btn">
			<img src="@/assets/img/fh.png" style="width: 20px;height: 20px;" />
			<div>返回</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, onMounted, nextTick, computed, watch, } from 'vue';
	import { ScenicApi, } from '@/plugins/api.js';
	import CreateList from "../components/createList.vue";
	import { ElMessage, ElNotification, } from "element-plus";
	import { getLodop } from "@/utils/LodopFuncs.js";
	import { useStore } from "vuex";
	import {
		printOut
	} from "@/utils/common.js";

	const producedLists = ref([]);  // 待制作列表数据
	const productionLists = ref([]);  // 制作中列表数据
	const completeLists = ref([]);  // 制作完成数据
	const statistic_list = ref({});  // 总数
	const isShowPrint = ref(false);  // 是否显示打印数据
	const printDatas = ref([]);  // 打印数据
	const store = useStore();
	const isShowMake = ref(true);  // 是否显示选择制作
	const makeOptions = ref([]);  // 选择制作点选项
	const isShowfoodPrint = ref(false);
	const screen_id = ref('');  // 选择制作点id
	const wsMessageEvent = computed(() => store.state.Config.wsMessageEvent);
	watch(wsMessageEvent, () => {
		// 监听是否有新订单
		if (wsMessageEvent.value.business == 'goods_make') {
			if (wsMessageEvent.value.type == 'goods_make_notice' || wsMessageEvent.value.type == 'goods_make_reload') {
				getMakeLists();
				if (wsMessageEvent.value.type == 'goods_make_notice') {
					ElNotification({
						title: '提示',
						message: '您有新的订单，请及时处理！',
						type: 'success',
					})
				}
			}
		}
	});
	/**
	 * 
	 * 获取后厨制作屏幕列表
	 *
	 * **/
	const goodsMakeScreenList = () => {
		ScenicApi.goodsMakeScreenList().then((res) => {
			if (res.Code === 200) {
				makeOptions.value = res.Data;
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 选择制作点
	 *
	 * **/
	const onClickName = (item) => {
		screen_id.value = item.id;
		getMakeLists();
	}
	/**
	 * 
	 * 获取制作列表数据
	 *
	 * **/
	const getMakeLists = () => {
		ScenicApi.getMakeLists({ screen_id: screen_id.value }).then((res) => {
			if (res.Code === 200) {
				// console.log("制作", res)
				isShowMake.value = false;
				document.getElementById('left-nav').style.display = 'none';
				document.getElementById('top').style.display = 'none';
				document.getElementById('top-menu').style.display = 'none';
				document.getElementById('main-wp').style.display = 'block';
				producedLists.value = res.Data.todo_list;
				productionLists.value = res.Data.doing_list;
				completeLists.value = res.Data.done_list;
				statistic_list.value = res.Data.statistic_list;
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 改变状态
	 *
	 * **/
	const changeStatus = (gm_id, type) => {
		ScenicApi.changeMakeStatus({
			gm_id,
			type
		}).then((res) => {
			getMakeLists();
			if (res.Code === 200) {
				printDatas.value = res.Data ? res.Data : [];
				// 制作按钮才打印
				if (type == '1') {
					if (res.Data.print_type == '1') {
						// 水吧打印
						if (printDatas.value.goods_list.length > 0) {
							isShowPrint.value = true;
							nextTick(() => {
								printLabels(printDatas.value.goods_list);
							})
						}
					} else {
						// 食物打印
						if (printDatas.value.goods_list.length > 0) {
							isShowfoodPrint.value = true;
							foodPrintLabels();
						}
					}
				}
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 制作按钮
	 *
	 * **/
	const onMakeBtn = (row) => {
		changeStatus(row.gm_id, 1);
	}
	/**
	 * 
	 * 制作完成按钮
	 *
	 * **/
	const onProductioncompletedBtn = (row) => {
		changeStatus(row.gm_id, 2);
	}
	/**
	 * 
	 * 打印按钮
	 *
	 * **/
	const onClickPrintBtns = (row) => {
		ScenicApi.getGoodsMakeprint({
			gm_id: row.gm_id,
		}).then((res) => {
			if (res.Code === 200) {
				printDatas.value = res.Data ? res.Data : [];
				if (res.Data.print_type == '1') {
					// 水吧打印
					if (printDatas.value.goods_list.length > 0) {
						isShowPrint.value = true;
						nextTick(() => {
							printLabels(printDatas.value.goods_list);
						})
					}
				} else {
					// 食物打印
					if (printDatas.value.goods_list.length > 0) {
						isShowfoodPrint.value = true;
						foodPrintLabels(printDatas.value);
					}
				}
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 水吧打印
	 * 
	 * **/
	const printLabels = (datas) => {
		let LODOP = getLodop(); //调用getLodop获取LODOP对象
		LODOP.SET_LICENSES("", "7AC1CC57DCDF3C17D8C5361830CD3E84", "", "");
		LODOP.SET_PRINT_MODE("POS_BASEON_PAPER", true);
		datas.forEach((el, index) => {
			LODOP.ADD_PRINT_HTM(
				5,
				20,
				"100%",
				"100%",
				document.getElementById(`ticket${index}`).innerHTML
			);
			/** SET_PRINT_PAGESIZE(intOrient,intPageWidth,intPageHeight,strPageName);
			intOrient：打印方向及纸张类型
					   1-- - 纵向打印，固定纸张；
					   2-- - 横向打印，固定纸张；
					   3-- - 纵向打印，宽度固定，高度按打印内容的高度自适应(见样例18) ；
					   0-- - 方向不定，由操作者自行选择或按打印机缺省设置
			intPageWidth：纸张宽，单位为0.1mm 譬如该参数值为45，则表示4.5mm,计量精度是0.1mm
			intPageHeight：固定纸张时该参数是纸张高；高度自适应时该参数是纸张底边的空白高，计量单位与纸张宽一样
			**/
			LODOP.SET_PRINT_PAGESIZE(1, 400, 300, "");  // 设置打印纸张大小
			LODOP.NewPage();
		});
		LODOP.PRINT();  // 没有预览直接打印
		// LODOP.PREVIEW();  // 预览打印
		isShowPrint.value = false;
	}
	/**
	 * 
	 * 食物打印
	 * 
	 * **/
	const foodPrintLabels = () => {
		isShowfoodPrint.value = false;
		nextTick(() => {
			printOut('foodPreparation');
		})
	}
	/**
	 * 
	 * 点击返回按钮
	 * 
	 * **/
	const onReturnBtn = () => {
		location.reload();  // 强制刷新页面
	}
	onMounted(() => {
		goodsMakeScreenList();
	});
</script>

<style lang="scss" scoped>
	.main-cnts {
		height: calc(100% - 80px);
		border-radius: 10px;
		background-color: var(--bg-color);
		margin: 0 25px;
	}

	.make-point {
		border-radius: 5px;
		padding: 20px 16px;
		width: 560px;
		height: 500px;
		border: 1px solid var(--border-grey-color);
		overflow: auto;
	}

	.make-point-name {
		margin-top: 10px;
		font-size: 14px;
		background-color: var(--search-bg-color);
		color: var(--theme-color);
		padding: 10px 15px;
		border-radius: 5px;
		margin-right: 10px;
	}

	.middle {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.return-btn {
		position: fixed;
		right: 5%;
		bottom: 15%;
		z-index: 100;
		background: #0072F6;
		color: #fff;
		width: 55px;
		height: 55px;
		text-align: center;
		border-radius: 50%;
		font-size: 14px;
		cursor: pointer;
		padding: 5px 0;
	}

	.kitchen-manage {
		font-family: "Source Han Sans CN";
		margin-top: 10px;

		.xs-m-l {
			margin-left: 5px;
		}

		.s-m-l {
			margin-left: 10px;
		}

		.m-m-l {
			margin-left: 15px;
		}

		.s-m-b {
			margin-bottom: 10px;
		}

		.s-p-b {
			padding-bottom: 10px;
		}

		.text-blue {
			color: var(--theme-color);
		}

		.text-white {
			color: var(--text-white-color);
		}

		.text-black {
			color: var(--text-second-color);
		}

		.to-be-produced {
			background-color: #0072F6;
		}

		.in-production {
			background-color: #F6C101;
		}

		.production-completed {
			background-color: #B2D3FC;
		}

		.no-data {
			text-align: center;
			padding: 20px 0;
		}

		.kitchen-content {
			margin: 0 25px;
			/* height: calc(100% - 10px); */
			height: 90vh;
		}

		.make {
			border-radius: 15px;
			height: 100%;
		}

		.make-title {
			font-size: 30px;
			font-weight: bold;
			padding: 15px 20px;
		}

		.subtitle {
			padding: 0 20px 10px 20px;
			font-size: 18px;
		}

		.complete-lists {
			flex-flow: wrap;
		}

		.complete-list-item {
			padding: 10px 30px;
			margin: 0 10px 10px 10px;
			border: 1px solid var(--search-uncheck-bg-color);
			border-radius: 5px;
			line-height: 42px;
			background-color: var(--text-white-color);
			color: var(--theme-color);
			font-size: 18px;
			font-weight: bold;
		}
	}
</style>